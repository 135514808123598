// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Spin, { SpinProps } from 'antd/lib/spin';

function LABELOPSLoadingSpinner(props: SpinProps): JSX.Element {
    return (
        <div className='labelops-spinner-container'>
            <Spin className='labelops-spinner' {...props} />
        </div>
    );
}

export default React.memo(LABELOPSLoadingSpinner);
